import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/DSC07021.jpg";
import MapGeorgetown from "../../images/minimaps/reports/minimap-guyana-georgetown.svg";
export const meta = {
  name: "guyana",
  title: "El metal tóxico a la sombra de la industria del oro",
  intro: "A pesar de su riesgo para la salud y el medio ambiente, el mercurio se utiliza ampliamente en la minería del oro, y para los mineros de la Amazonía, el líquido plateado es casi tan valioso como el propio oro.",
  by: "Por Bram Ebus (texto y fotos) y G. I. Sutherland (texto). Videos por Tom Laffay",
  image: Image,
  next: {
    slug: "suriname",
    title: "La fiebre del oro amenaza al país más verde del mundo",
    description: "Las vías fluviales y los vientos de Surinam llevan el mercurio, un metal tóxico utilizado en la extracción de oro, a regiones muy alejadas de las minas de oro del país."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const Gallery = makeShortcode("Gallery");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Es tarde en Corriverton,`}</strong>{` un pequeño pueblo en la ribera occidental del río Corentin, la vía fluvial que separa Guyana de Surinam, pero unos pocos barqueros locales, que transportan personas y hacen contrabando a través del río, siguen merodeando frente al burdel del pueblo, bebiendo cervezas y fumando marihuana mientras charlan con las trabajadoras sexuales que han salido por la noche. La mayoría de las mujeres son refugiadas venezolanas que huyeron de la crisis en su país de origen o fueron traficadas en el extranjero.`}</p>
    <p>{`En un callejón oscuro junto al burdel, Showtime* fuma un cigarrillo y vigila a algunas de las mujeres. El nativo guyanés pasó años viviendo y trabajando en los Estados Unidos de América, donde afirma haber dirigido a un grupo de trabajadoras sexuales. De vuelta en Guyana, sigue involucrado en la industria del sexo, pero ahora su negocio principal es llevar contrabando al otro lado del río.`}</p>
    <p>{`“Nunca va a ser un problema llevar tus vainas hacia allá”, dice, apuntando al este hacia el río y Surinam. Su grueso collar de oro roza la parte superior de su redondeado vientre mientras se inclina casualmente contra la pared. Las fuerzas del orden público siempre están un paso atrás. “No importa lo que hagan”, dice. “Tienen que construir una cerca en el maldito río”.`}</p>
    <Video url="https://youtube.com/embed/fqPOWU235qI" mdxType="Video" />
    <p>{`Dos productos básicos que suelen transportarse ilegalmente son el oro y el mercurio, y en la región del Escudo Guyanés, uno no va sin el otro. El oro es el principal producto de exportación de Guyana, gracias en gran parte al mercurio, el tóxico azogue utilizado en su proceso de extracción. En el 2015, Guyana produjo 19,1 toneladas de oro, de acuerdo con los registros oficiales, para lo que requirió un `}<a parentName="p" {...{
        "href": "https://www.iucn.nl/files/groene_economie/regional_mercury_report_guyana.pdf"
      }}>{`estimado de 29 toneladas de mercurio`}</a>{`. Es probable que ambas cifras ahora sean más altas, ya que la producción de oro ha aumentado desde el 2015 y una cantidad significativa de oro guyanés se trafica al extranjero.`}</p>
    <p>{`Todo ese mercurio tiene graves repercusiones en la salud humana y el medio ambiente, pero los esfuerzos realizados hasta ahora para reducir su uso en la industria aurífera solo han llevado las cadenas de suministro a la clandestinidad, dejando a muchos mineros expuestos tanto a las consecuencias nefastas de la sustancia tóxica para la salud como a los riesgos legales de participar en el mercado negro.`}</p>
    <hr></hr>
    <Sidenote side="left" mdxType="Sidenote">
	<MapGeorgetown mdxType="MapGeorgetown" />
    </Sidenote>
    <p>{`Guyana y Surinam comparten los mismos ecosistemas amazónicos, el acceso al Océano Atlántico Norte y el pasado colonial. Ambos países sobrellevaron siglos de ocupaciones británicas y holandesas, independizandose apenas en el siglo XX.`}</p>
    <iframe src="https://datawrapper.dwcdn.net/R9mcW/1/"></iframe>
    <p>{`Guyana es una nación especialmente azotada por la pobreza, plagada de corrupción y subdesarrollo, el daño colateral de su larga historia colonial, un ingreso bruto per cápita de apenas $4.760 al año. Casi un tercio de la población total del país, que asciende a 780.000 habitantes, vive en la capital, Georgetown, donde miles de familias viven en edificaciones que requieren reparaciones con urgencia. Casas de madera en ruinas y depósitos de chatarra urbanos no son una visión poco común; sin embargo, los economistas tienen una visión positiva sobre el futuro del país.`}</p>
    <Video url="https://youtube.com/embed/GtkqArJG52Y" mdxType="Video" />
    <p>{`Guyana está a punto de convertirse en uno de los países más ricos de América Latina. En el 2015, se descubrieron enormes depósitos de petróleo en el mar que reportarán alrededor de 1,2 millones de barriles al día muy brevemente.`}</p>
    <p>{`Mientras que el petróleo es la comidilla de la ciudad en Georgetown, los 20.000 mineros del país no se sienten representados por sus políticos.`}</p>
    <p>{`En el 2013, Guyana firmó el Convenio de Minamata, un tratado mundial destinado a reducir las emisiones de mercurio para proteger la salud humana y el medio ambiente. El convenio, que lleva el nombre de una ciudad de Japón donde los residentes se envenenaron con el mercurio liberado por una fábrica local, solicita la prohibición del uso de mercurio en la minería. Guyana ratificó el convenio en el 2014 y se comprometió a reducir el uso de mercurio hasta un 75% de los niveles basales para el 2027.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Tenemos que conseguir “silver” para obtener oro”, dice un minero experimentado en el interior de Guyana.`}</p>
    </blockquote>
    <p>{`Newell Dennison, comisionado de la Comisión de Geología y Minas de Guyana (GGMC), indica que ya se han dado los primeros pasos. “Ya estamos en el sector saliendo a las áreas aglomeradas”, donde la GGMC discute técnicas mineras alternativas con los propios mineros. Cerca de 980 mineros ya han recibido demostraciones, encuestas o talleres, pero su decisión de continuar con el mercurio o no hasta ahora depende de su eficacia. “Mucho de lo que está sucediendo está determinado por lo que hacen los amigos y lo que hacen otros mineros”, añade Dennison. “Reconocemos que no se trata únicamente de que el GGMC salga y haga cosas... también se trata de un cambio de cultura”.`}</p>
    <p>{`En los bosques del interior de Guyana, la inminente prohibición de mercurio es recibida con descontento por las comunidades de mineros artesanales y de pequeña escala, quienes temen por su sustento, puesto que su producción de oro depende de la disponibilidad de mercurio ―también conocido como azogue, o para muchos mineros, simplemente como “silver”.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABynrvECkf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECERD/2gAIAQEAAQUCV2bY3QnzD//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BpX//xAAXEAADAQAAAAAAAAAAAAAAAAAAIDIx/9oACAEBAAY/AqLNT//EABsQAAICAwEAAAAAAAAAAAAAAAARASEQcYGh/9oACAEBAAE/IRpkufQxbdwJmx//2gAMAwEAAgADAAAAEAc//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFR/9oACAEDAQE/EEgf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCdFv/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUWFxgaH/2gAIAQEAAT8QBWvakPyPFfkbd1yqJRg9x23TyRz3n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/e93cc/slider1-1.webp 300w", "/static/1e663f43f784184f2980b8410879b2ed/b0544/slider1-1.webp 600w", "/static/1e663f43f784184f2980b8410879b2ed/68fc1/slider1-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/73b64/slider1-1.jpg 300w", "/static/1e663f43f784184f2980b8410879b2ed/3ad8d/slider1-1.jpg 600w", "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg",
              "alt": "La inminente prohibición de mercurio es recibida con descontento por las comunidades de mineros artesanales",
              "title": "La inminente prohibición de mercurio es recibida con descontento por las comunidades de mineros artesanales",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span><em parentName="p">{`La inminente prohibición de mercurio es recibida con descontento por las comunidades de mineros artesanales`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVcGc1GZl//EABsQAAICAwEAAAAAAAAAAAAAAAABAjEDERIh/9oACAEBAAEFAt+OiaRZDHHn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAVEAEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAQAGPwIr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMWEhUYH/2gAIAQEAAT8h5JUexsU909FkojLom0//2gAMAwEAAgADAAAAEAPP/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QWP/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDMZN//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUGRsf/aAAgBAQABPxCrpAwrbGBZXJbbhcMND7OToS8BVn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/e93cc/slider1-2.webp 300w", "/static/abe20d9c565ffe84554a700779109812/b0544/slider1-2.webp 600w", "/static/abe20d9c565ffe84554a700779109812/68fc1/slider1-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/73b64/slider1-2.jpg 300w", "/static/abe20d9c565ffe84554a700779109812/3ad8d/slider1-2.jpg 600w", "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAEVJs7zyR//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISA//aAAgBAQABBQJc0KZRdTpTo3Rk/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhAhEi/9oACAEBAAY/AroWm/I6hs52FP/EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhQf/aAAgBAQABPyEAvqMn3WaK65bdG40nV9w4chJNK307/9oADAMBAAIAAwAAABDr/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjIz/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QqKj/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMWFBcf/aAAgBAQABPxCVBC1NGOC7TNqM8CYVwJCP3KY1qOs9yB204J+YrbZ7Jn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/e93cc/slider1-3.webp 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/b0544/slider1-3.webp 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/68fc1/slider1-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/73b64/slider1-3.jpg 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/3ad8d/slider1-3.jpg 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB4VJham2//8QAGRABAQEAAwAAAAAAAAAAAAAAAgMBABES/9oACAEBAAEFAr51WUnV0m5rzj4Ggtu93//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhQQISYf/aAAgBAQAGPwKNZ1CKYRH1PXs5ZK2f/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8hfSgKLI3UOyk5J6vJ3r+2UFuNwW+T/9oADAMBAAIAAwAAABBbz//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QdWFZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxABywv/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFhsZH/2gAIAQEAAT8Qg0Ku8O+9ZfOZ6N1PcNC0Kp1+YaBDOzQn377gayBBKFOGd4yAlZGf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/e93cc/slider1-4.webp 300w", "/static/a230acc338c77b1d6d56942ce2389e28/b0544/slider1-4.webp 600w", "/static/a230acc338c77b1d6d56942ce2389e28/68fc1/slider1-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/73b64/slider1-4.jpg 300w", "/static/a230acc338c77b1d6d56942ce2389e28/3ad8d/slider1-4.jpg 600w", "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABF2ZubHLjf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhMDEv/aAAgBAQABBQLIXJGJmhRLfmT/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABgQAAMBAQAAAAAAAAAAAAAAAAAQMQIh/9oACAEBAAY/AquaUIf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUGR/9oACAEBAAE/IcRwIa81jVuaRJu/R5fA0VE//9oADAMBAAIAAwAAABAn/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EAFhy//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QU2vb/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFBYZH/2gAIAQEAAT8QeCF7Ka9UBUgGskPrgz3b6xoU+S3Pg+zv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/e93cc/slider1-6.webp 300w", "/static/2a90523be06c260163442a74d8eef870/b0544/slider1-6.webp 600w", "/static/2a90523be06c260163442a74d8eef870/68fc1/slider1-6.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/73b64/slider1-6.jpg 300w", "/static/2a90523be06c260163442a74d8eef870/3ad8d/slider1-6.jpg 600w", "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAByZYrKxk//8QAGhAAAgMBAQAAAAAAAAAAAAAAABEBAhIDIf/aAAgBAQABBQK9+ScGWQjJ7B//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BkY//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBESEQ/9oACAEBAAY/Aloiy53jP//EABwQAAICAgMAAAAAAAAAAAAAAAERAEEhUWGBof/aAAgBAQABPyE0iMt6jWiPLhK7qAhFooLPyA4Gn//aAAwDAQACAAMAAAAQRC//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QgE//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QAYW3/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8QYWnV8F2h7K1teMInbn1qoYChosPszQS9og2lE//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/e93cc/slider1-7.webp 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/b0544/slider1-7.webp 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/68fc1/slider1-7.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/73b64/slider1-7.jpg 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/3ad8d/slider1-7.jpg 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABly+kaQjGf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQJlT51gntMqFv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQARICExQf/aAAgBAQAGPwLBa4Gj/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyG7mGLekUbh4iIyHgStcJ92f//aAAwDAQACAAMAAAAQvN//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EFo6f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QVOQGX//EABwQAQEAAgIDAAAAAAAAAAAAAAERADEhQWFxgf/aAAgBAQABPxCkO1F5AHq4aDgQdx5PZiE+4ihaJmgFdwxCaLn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/e93cc/slider1-8.webp 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/b0544/slider1-8.webp 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/68fc1/slider1-8.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/73b64/slider1-8.jpg 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/3ad8d/slider1-8.jpg 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAByZtrzooWMf/EABkQAQEAAwEAAAAAAAAAAAAAAAIAARARMf/aAAgBAQABBQIyGjjk/b//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BgX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGn/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwI2O//EABwQAAICAgMAAAAAAAAAAAAAAAERACEQQTFRkf/aAAgBAQABPyFa5ugIUWWJ1CFT8gi2XcZizeP/2gAMAwEAAgADAAAAENjP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EENJ/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EM1EX//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExQVFxgaH/2gAIAQEAAT8Q3CJUDK5ROG8vB37wmJ0NYpcGktS9JoYQBm6rWHH3Fuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/e93cc/slider1-9.webp 300w", "/static/cccefa22028a624723d3e02a2f0e0477/b0544/slider1-9.webp 600w", "/static/cccefa22028a624723d3e02a2f0e0477/68fc1/slider1-9.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/73b64/slider1-9.jpg 300w", "/static/cccefa22028a624723d3e02a2f0e0477/3ad8d/slider1-9.jpg 600w", "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABXwUkRWFUP//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQMAEv/aAAgBAQABBQKtorpD4YhkhzF37//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BjX//xAAcEAABAwUAAAAAAAAAAAAAAAABABAiAhESIUH/2gAIAQEABj8CkWjSbLRWJ43/xAAbEAEBAAIDAQAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPyEw2SXxgZ1Hbc9gxttNvtm//9oADAMBAAIAAwAAABCHP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAwn//EABcRAQADAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8Qni7/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMVFBYXH/2gAIAQEAAT8QQiFUml+59Bw5mcrBVbHOnMDwdSw4q16QZhh1P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/e93cc/slider1-10.webp 300w", "/static/592d3f32de4ad415d251170532370a2f/b0544/slider1-10.webp 600w", "/static/592d3f32de4ad415d251170532370a2f/68fc1/slider1-10.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/73b64/slider1-10.jpg 300w", "/static/592d3f32de4ad415d251170532370a2f/3ad8d/slider1-10.jpg 600w", "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`En la víspera de las elecciones presidenciales de este año, ganadas por el candidato de la oposición Irfaan Ali después de un largo y desastroso conteo de votos, la Asociación de Mineros de Oro y Diamantes de Guyana (GGDMA) y el Sindicato Nacional de Minería (NMS) publicaron una `}<a parentName="p" {...{
        "href": "(https://ggdma.com/pressnews/a-manifesto-for-mining/)"
      }}>{`lista de exigencias`}</a>{` para los políticos que buscan el apoyo político de las organizaciones. La primera era que, antes de eliminar paulatinamente el uso del mercurio, el gobierno debía facilitar a los mineros tecnologías alternativas asequibles, junto con la asistencia financiera y técnica necesarias para su uso.`}</p>
    <p>{`“Si el mercurio pasa a la clandestinidad, se vuelve más costoso, mucho más costoso, porque es ilegal”, dice Kennard Williams*, un operador minero. Los comerciantes de mercurio a cargo del suministro se harán ricos, dice, mientras que los mineros hacen “todo el trabajo duro”.`}</p>
    <p>{`Gabriel Lall, ex presidente de la Junta de Oro de Guyana (GGB en inglés), el organismo estatal que dirige el comercio de oro, está de acuerdo en que la prohibición del mercurio probablemente “fomente la proliferación de empresas criminales”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Nuestras fronteras son largas y porosas; nuestros funcionarios ―con el debido respeto a los pocos que hay rectos― se sabe que miran hacia otro lado por un precio”`}</p>
    </blockquote>
    <p>{`Lall advierte que en Guyana hay corrupción generalizada y que los agentes de orden público suelen hacerse la vista gorda ante el comercio ilícito a cambio de sobornos. “Nuestras fronteras son largas y porosas; nuestros funcionarios ―con el debido respeto a los pocos que hay rectos― se sabe que miran hacia otro lado por un precio”, dice. “Si hay puntos débiles en algún eslabón de la cadena de vigilancia, entonces es mejor no tener una cadena”. La policía no respondió a una solicitud de comentarios.`}</p>
    <hr></hr>
    <p>{`Guyana es el único país de la región del Escudo Guayanés que aún importa mercurio bajo licencia. Del 2008 al 2013, el promedio de las importaciones anuales fue de alrededor de 70.000 kg, pero después de que Guyana firmó el Convenio Minamata, en señal de su apoyo a una reducción (y futura prohibición) del mercurio, las importaciones se precipitaron, promediando poco más de 20.000 kg en los años posteriores. La mayor parte del mercurio, que proviene principalmente de Rusia, Reino Unido, India, Estados Unidos de América y Turquía, entró en Guyana por el puerto marítimo de Georgetown.`}</p>
    <iframe title="Mercury imports in Guyana (2008-2018)&amp;nbsp;" aria-label="Column Chart" src="//datawrapper.dwcdn.net/Wqe1W/1/" scrolling="no" frameBorder="0" style={{
      "border": "none"
    }} width="600" height="400"></iframe>
    <p>{`Se generarán ganancias astronómicas en el comercio. El precio de venta al público del mercurio en Guyana puede llegar a superar 10 veces el precio de importación. En promedio, el mercurio se importa a $17.40 por kilo. Los mayoristas lo venden a $126, pero en las zonas mineras, el azogue se vende fuera del mercado por unos 159 a 234 dólares.`}</p>
    <p>{`Aunque la prohibición aún no está en vigor, hablar de mercurio ya es tabú para los importadores autorizados. Ninguno de ellos accedió a conceder una entrevista oficial, pero un antiguo importador, quien almacenó suficiente mercurio para seguir vendiéndolo en el mercado nacional, sugiere que se están pagando sobornos para obtener las licencias, que son gestionadas por la Junta de Control de Plaguicidas y Productos Químicos Tóxicos de Guyana (PTCBB, en inglés). “Ya ves, empiezas a meterte en política más profunda”, dice sonriendo mientras se agarra las manos frente al mostrador de su tienda en Georgetown. (La Junta no hizo comentarios sobre las denuncias de soborno, pero afirma que “no tiene información que corrobore el presunto tráfico de mercurio hacia Guyana”).`}</p>
    <p>{`Responde cada pregunta cuidadosamente, sopesando en silencio cada respuesta antes de hablar; pero, cuando se le pregunta si todas las importaciones aún son legales, reacciona de inmediato. “Jesucristo, sabes que ni siquiera has tocado la punta del iceberg”, el empresario de Georgetown se ríe. “En este momento ya lo están introduciendo de contrabando en este país”.`}</p>
    <p>{`El comerciante de Georgetown, tres expertos y otras fuentes del sector afirman que, además de las importaciones legales, los comerciantes chinos están canalizando el mercurio ilícito hacia el puerto marítimo de Georgetown.`}</p>
    <Video url="https://youtube.com/embed/lJuft01OY1E" mdxType="Video" />
    <p>{`Los importadores chinos tienen mucha influencia sobre el gobierno de Guyana, según una fuente guyanesa que trabaja para el Estado. “Estos tipos tienen acceso”, dice. “Estos tipos se sienten cómodos retorciendo el brazo para pedir favores por los que pagan muy, muy bien."`}</p>
    <blockquote>
      <p parentName="blockquote">{`La facilidad con la que el mercurio se mueve alrededor de Guyana ha hecho del país una puerta de entrada para canalizar hacia los países vecinos`}</p>
    </blockquote>
    <p>{`Una vez que entra a Guyana, no hay restricciones en el comercio o movimiento del mercurio por el país. La facilidad con la que el mercurio se mueve alrededor de Guyana ha hecho del país una puerta de entrada para canalizar hacia los países vecinos, indica el ex-ministro de medio ambiente de Guyana, Raphael Trotman. “Estoy seguro del tráfico transfronterizo”, escribió a través de WhatsApp. “La mayoría de la información que he recibido habla de Surinam, pero no puedo descartar los demás”.`}</p>
    <hr></hr>
    <p>{`En el centro de Georgetown, pequeñas furgonetas combi recogen a los pasajeros y los llevan a todos los rincones del país. Los vehículos, pintados con colores y diseños llamativos, zigzaguean agresivamente entre el tráfico de la ciudad, tocando la bocina ante la más mínima provocación. Muchas de las furgonetas se dirigen a puestos remotos en la selva.`}</p>
    <p>{`Un conductor, que se dirige a Lethem, un pueblo en la frontera con Brasil, ofrece transportar mercurio hacia allá, a un costo. “Todo el mundo tiene que cobrar”, afirma, parado frente a la oficina de su compañía. “Porque recuerde, es un riesgo que se corre. Si lo vamos a pasar de contrabando, va a ser caro.” Los conductores cobran hasta $500 adicionales para llevar un frasco de 34,5 kg de mercurio al otro lado del río en Lethem. Usan cruces informales para evitar a la policía federal brasileña. Una vez que está a salvo en Brasil, los taxistas llevan el mercurio al mercado local en la ciudad de Boa Vista. A veces los vehículos privados lo llevan unas horas más al norte, a Venezuela.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Porque recuerde, es un riesgo que se corre. Si lo vamos a pasar de contrabando, va a ser caro.”`}</p>
    </blockquote>
    <p>{`Las furgonetas combi también abastecen a las ciudades mineras del interior de Guyana. Un flujo continuo de furgonetas, abarrotadas con pequeños equipos de minería y suministros de alimentos y repletas de pasajeros que compiten por los asientos en las ventanas, hacen el viaje de ocho horas a Mahdia.`}</p>
    <p>{`El enclave minero apenas fue declarado municipio en el 2018.La mayoría de las edificaciones del centro de la pequeña aldea son de madera. Hay algunos clubes nocturnos y una tienda de oro en casi todas las calles. La exuberante selva que rodea la aldea está marcada por arroyos secos y enormes fosas ocres, como si un meteorito se hubiera fraccionado justo encima del pueblo y hubiera dejado cráteres en los alrededores.`}</p>
    <Video url="https://youtube.com/embed/ExEVqQRdPqk" mdxType="Video" />
    <p>{`En una de estas fosas de la selva, un joven se aferra a una manguera de agua mientras el agua a alta presión sale de la boquilla; lo llaman “jetman” `}{`[operador de manguera a presión]`}{`. Lava el lodo que contiene oro en piscinas fangosas, donde un "pitman" `}{`[operador en fosa]`}{` trabaja hundido hasta el pecho en el agua removiendo piedras y otras piezas grandes de detrito. Desde allí, el "marrackman" `}{`[operador de bomba de succión]`}{` canalizar el lodo de las piscinas mediante la bomba de succión hacia el canalón ―otro artefacto que separa las piezas de oro gruesas de otras partículas suspendidas por peso.`}</p>
    <p>{`Bajo una estructura de palos de madera y una lámina de plástico se encuentra el supervisor de la operación, Kennard Williams*. Su trabajo es atrapar partículas de oro diminutas que se quedan en el lodo fangoso. “El oro es como polvo; es tan fino, que no puedes agarrarlo sin la plata”, dice, rociando el líquido plateado sobre una placa fina de amalgama.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABSHts2eUQ/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAvQm2cRnlJMqXw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAQAiFR/9oACAEBAAY/AogHNg1ku1//xAAaEAEBAQADAQAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/IdGYafJXltkOgyrLHqQ3/9oADAMBAAIAAwAAABBvL//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDVf//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBRkP/EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUUHh8P/aAAgBAQABPxBYq3nYwylIgfTFJ0xPWWVoG07mCU7secVrzn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/e93cc/slider-2-1.webp 300w", "/static/32b916684070e2673394ef92d5ad98cd/b0544/slider-2-1.webp 600w", "/static/32b916684070e2673394ef92d5ad98cd/68fc1/slider-2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/73b64/slider-2-1.jpg 300w", "/static/32b916684070e2673394ef92d5ad98cd/3ad8d/slider-2-1.jpg 600w", "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A maioria dos garimpeiros de Mahdia e dos arredores não está nessa atividade pelas riquezas; eles são mineros de subsistência.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABibE/N2ID/8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEQMSI//aAAgBAQABBQLUhy4+cLQoJc62/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhkf/aAAgBAQAGPwKzD2i30c//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8hUSceinczHeD0ZgsjIR//2gAMAwEAAgADAAAAEAcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQZFhwf/aAAgBAQABPxDSRSdr9uAJpgR47zYhePzFiWyc8Zc6NHWC04g7M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/e93cc/slider-2-2.webp 300w", "/static/1ffd1dfec0074983e29717a877469f81/b0544/slider-2-2.webp 600w", "/static/1ffd1dfec0074983e29717a877469f81/68fc1/slider-2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/73b64/slider-2-2.jpg 300w", "/static/1ffd1dfec0074983e29717a877469f81/3ad8d/slider-2-2.jpg 600w", "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABlrKuhRsUB//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhIiERMh/9oACAEBAAEFAttpDEgmdIsQ8r//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAECAQE/AbGX/8QAGRAAAwADAAAAAAAAAAAAAAAAARARACEx/9oACAEBAAY/AhuR9wr/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAQABPyF1KCV70lfRu3S2usEo41yGrOH/2gAMAwEAAgADAAAAEHzv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QEz//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxARmf/EAB0QAQADAAMAAwAAAAAAAAAAAAEAESExQWGB0fD/2gAIAQEAAT8QQ1xB8FewOZx2to9idijp1kCgE2J+4lwrkeE+4qgNVvc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/e93cc/slider-2-3.webp 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/b0544/slider-2-3.webp 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/68fc1/slider-2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/73b64/slider-2-3.jpg 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/3ad8d/slider-2-3.jpg 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGTEvCoLCS//8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEhETIf/aAAgBAQABBQLt9FZXPBfNqOkQn//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQESExQZH/2gAIAQEABj8Cxbb61hQAv//EABsQAAMBAQADAAAAAAAAAAAAAAABESFBMZGx/9oACAEBAAE/Ia9TCi7nYUAxzXn4Jx63zBTCkj//2gAMAwEAAgADAAAAEKM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEDAQE/EJqH/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBw7J2//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIVExYbH/2gAIAQEAAT8QASpbZG2HhChbbX5gKrZ6Jpelpa10OzWSgrb/AFDLngQQT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/e93cc/slider-2-4.webp 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/b0544/slider-2-4.webp 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/68fc1/slider-2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/73b64/slider-2-4.jpg 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/3ad8d/slider-2-4.jpg 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVdR6YaRMG//xAAaEAACAgMAAAAAAAAAAAAAAAACAwABBBAR/9oACAEBAAEFAhe2W5kDIMBuc1//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRICFB/9oACAEBAAY/AulOWik1h//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQWGB/9oACAEBAAE/Iem/TEgm3ssUnO7LKbAGbDZ//9oADAMBAAIAAwAAABB83//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAwEBPxCMf//EABgRAAIDAAAAAAAAAAAAAAAAAABRAREh/9oACAECAQE/ENZUs//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWFxgf/aAAgBAQABPxAjWTFBK+xluwfKuGuYSsh7RfVxlDNrIsAWDazKzN9z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/e93cc/slider-2-5.webp 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/b0544/slider-2-5.webp 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/68fc1/slider-2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/73b64/slider-2-5.jpg 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/3ad8d/slider-2-5.jpg 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Se enciende un generador y un chorro de agua fangosa corre sobre la placa. El agua y el limo corren a través de ella mientras la amalgama queda atrapada en la parte superior. De vez en cuando, Williams vierte más mercurio sobre la placa, a veces usando hasta más de un kilo al día. Todo el proceso toma en promedio unos pocos días y múltiples placas de amalgama son utilizadas.`}</p>
    <Video url="https://youtube.com/embed/dOcQdbrynXo" mdxType="Video" />
    <p>{`La mayoría de los mineros en Mahdia y sus alrededores no lo hacen por las riquezas, sino por la subsistencia. Incluso después de largos días de trabajo agotador, los mineros apenas pueden mantener a sus familias. Dexton Grant, un minero veterano que recientemente comenzó a trabajar para Williams, lleva tablas pesadas de madera sobre sus hombros mientras se desliza entre las lagunas contaminadas de la mina. Hay pocas oportunidades de trabajo en Mahdia fuera del sector minero, afirma Grant. Está agradecido por la oportunidad de poner comida en la mesa de su familia.`}</p>
    <p>{`La falta de oportunidades educativas y de inversiones estatales en la región ha condenado a generaciones de aldeanos a una vida en las fosas fangosas. Grant espera que sus hijos tengan la oportunidad de dedicar sus vidas a otra cosa. “Estoy en las minas para conseguir este oro”, dice, y “para que puedan ir a la escuela”.`}</p>
    <Video url="https://youtube.com/embed/4SJe-illSZw" mdxType="Video" />
    <p>{`A los mineros artesanales se les suele pagar en función de lo que producen; cuanto menos oro puedan extraer, menos se les paga. Si el mercurio no se puede seguir usando, Grant dice que eso será su salida del sector. “No sirve de nada sin el mercurio, porque este atrapa mucho más el oro”, dice. Está más preocupado por su bienestar económico que por su salud.`}</p>
    <p>{`En opinión de los especialistas, no hay un nivel seguro de exposición al mercurio, pero Grant afirma que el mercurio no ha afectado su cuerpo. Es una creencia que comparten muchos mineros en Guyana.`}</p>
    <Video url="https://youtube.com/embed/noKstuzu1FQ" mdxType="Video" />
    <p>{`Harry Casey*, un lugareño de Mahdia que dirige un proyecto minero en las cercanías, no entiende por qué la gente le advierte sobre el uso del mercurio. “Todavía me desconcierta ¿por qué es tan peligroso el mercurio?” pregunta en voz alta, conduciendo su Toyota a través de la selva entre las minas. Sonríe cuando recuerda haber jugado con mercurio de niño en las visitas a la mina de su padre, quien usaba “cubos de mercurio” como minero y hoy en día sigue vivo. “Mi padre tiene 84 años”, dice. “Tuvo 17 hijos con mi madre”.`}</p>
    <p>{`Las concepciones erróneas sobre los riesgos a la salud por la minería con mercurio constituyen un problema grave en Guyana. Sin embargo, los mineros en sí no son el grupo más afectado por la contaminación con mercurio. En su forma líquida, tal como la usan los mineros, el mercurio es menos peligroso para la salud humana que en su forma gaseosa. Los trabajadores de las tiendas de oro, que queman el mercurio de la amalgama, están más expuestos a los vapores tóxicos.`}</p>
    <hr></hr>
    <p>{`En medio de una de las aldeas de los yacimientos de oro guyaneses del interior, Leroy Persaud* se ríe nerviosamente detrás de su gran escritorio en su tienda de oro, una estructura de madera de un piso, protegida por barras de metal sólido. En el 2013, después de casi dos años de comprar oro de las minas y los mineros, quemar el mercurio de las esponjas de amalgama y fundir el oro en pequeños lingotes, su salud comenzó a deteriorarse.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylrTAND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaJzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcArEcejSfjsyJ9rwo/9oADAMBAAIAAwAAABCT7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBkf//EAB0QAQADAAEFAAAAAAAAAAAAAAEAETEhQVFhcaH/2gAIAQEAAT8QQzO7NHavsSoyOhfUpkQVVo8RKbGvECC7F3Kquq8s/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/e93cc/DSC07015.webp 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/b0544/DSC07015.webp 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/68fc1/DSC07015.webp 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/0b978/DSC07015.webp 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/73b64/DSC07015.jpg 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/3ad8d/DSC07015.jpg 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/22cab/DSC07015.jpg 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg",
            "alt": "El oro amalgamado",
            "title": "El oro amalgamado",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span><em parentName="p">{`El oro amalgamado`}</em>{` `}</p>
    <p>{`“Las cosas empezaron a salirse de control en mí”, explica Persaud. Más tarde ese año, empezó a despertarse con dolores de cabeza, padecer de diarrea y vómitos, y perder la vista. También se puso de mal humor. “Terminé golpeando a mi novia”, dice. “Nunca solía hacer eso”. En el 2013, Persaud fue a la clínica local de su pueblo, donde dio negativo para malaria y dengue. Sus síntomas siguieron empeorando y, después de un incidente particularmente traumático, en el que Persaud empezó a temblar descontroladamente y casi perdió la vista por completo, sus médicos locales lo enviaron a la capital. “Pensé que iba a morir”, recuerda.`}</p>
    <p>{`En el laboratorio médico Eureka Laboratories de Georgetown, el único centro equipado en ese entonces para probar la intoxicación por mercurio, sus análisis de sangre mostraron que sus niveles de mercurio eran de 160 nanogramos por mililitro (ng/mL), por lo menos 10 veces por encima de los niveles`}</p>
    <blockquote>
      <p parentName="blockquote">{`“El médico en Brasil me dijo: ‘Si quieres vivir, deja el trabajo’”, dice Persaud. “Pero este es el único trabajo que sé hacer para sobrevivir y mantener a mi familia`}</p>
    </blockquote>
    <p>{`normales. Los médicos le aconsejaron que abandonara su trabajo y la región minera, pero no había ningún tratamiento disponible en Guyana. Una ex novia le instó a que fuera a un hospital en Manaos, Brasil, pero el tratamiento era costoso y Persaud andaba escaso de dinero. Para reunir los 2,5 millones de GYD que necesitaba para el viaje y el tratamiento, volvió a trabajar en su tienda de oro. Cuando finalmente llegó al hospital Saint Julia en Manaos, los médicos no entendieron cómo seguía vivo ―las últimas pruebas revelaron que los niveles de mercurio en su sangre habían subido a 320 ng/mL. “El médico en Brasil me dijo: ‘Si quieres vivir, deja el trabajo’”, dice Persaud. “Pero este es el único trabajo que sé hacer para sobrevivir y mantener a mi familia; sin embargo, volví preparado”.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHjyZLIJf/EABoQAQABBQAAAAAAAAAAAAAAAAECABASITH/2gAIAQEAAQUC7aM8QUXbX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwFkf//EABYQAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwKibf/EABsQAQEBAAIDAAAAAAAAAAAAAAERABAhMUFR/9oACAEBAAE/IWe7fgZ6o4YRytIPCZ0Xj//aAAwDAQACAAMAAAAQMC//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8QIEz/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdXZa/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITEQ/9oACAEBAAE/ENQ4csXAELY4MrNtvs3poiki9Ut+f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/e93cc/DSC07030.webp 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/b0544/DSC07030.webp 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/68fc1/DSC07030.webp 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/4b075/DSC07030.webp 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/73b64/DSC07030.jpg 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/3ad8d/DSC07030.jpg 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/38b44/DSC07030.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg",
            "alt": "En una tienda de oro, el mercurio se quema antes de fundir el oro en pequeños lingotes.",
            "title": "En una tienda de oro, el mercurio se quema antes de fundir el oro en pequeños lingotes.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`En una tienda de oro, el mercurio se quema antes de fundir el oro en pequeños lingotes.`}</em></p>
    <p>{`Persaud sigue quemando la amalgama tóxica que le venden los mineros, pero ahora, totalmente equipado con una máscara y un extractor, Persaud argumenta que su actividad es “90% segura”. El tratamiento que trajo de Brasil, combinado con una dieta saludable y rica en vitaminas y una rutina de ejercicios para sudar el mercurio por sus poros, le ayudó a recuperar sus niveles a 10 ng/mL. “Realmente no lo ves, pero lo hueles”, se ríe Persaud. “El olor que solía tener ya no está ahí”.`}</p>
    <hr></hr>
    <p>{`Hoy en día, siete años después de que Persaud viajara a la capital, los mineros que quieren hacerse la prueba del mercurio en Guyana todavía tienen que acudir a Eureka Laboratories en la calle Thomas en Georgetown. En el interior, la Dra. Rhonda Archer ve a los pacientes en la camilla de su consultorio, separada de su escritorio por una cortina azul claro.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABT1kENo0mUprGtOAP/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAECAxESMTP/2gAIAQEAAQUCksLJxZyi5nTk6vm5C2T1jWjWHh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwEj/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAEREDFBAhIhUWH/2gAIAQEABj8CnUxbreHAukOM4QpGmTkvSFT/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVFxQWGB/9oACAEBAAE/ISNwNYhAAjT49yzOkEvVpdcwBDEiwAOyYKmsXAC5TghxD5CTZqANR6l6T//aAAwDAQACAAMAAAAQYx8C/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhEBH/2gAIAQMBAT8QThwcz//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QC9Zcbcsv/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWFxof/aAAgBAQABPxAXfBGlzQEuNfH4wMBUaQ5xxxbjtGfNmTvrHgcmiSgbguCEO6hS9YkQ0hqDAhPQ2wg5s5t/MZ0RPC4pd53XP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/e93cc/DSC07416.webp 300w", "/static/33838494518738afe5f24b53cd7c7d9a/b0544/DSC07416.webp 600w", "/static/33838494518738afe5f24b53cd7c7d9a/8d2ea/DSC07416.webp 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/73b64/DSC07416.jpg 300w", "/static/33838494518738afe5f24b53cd7c7d9a/3ad8d/DSC07416.jpg 600w", "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg",
            "alt": "Mineiro queima o mercúrio em uma mina de ouro",
            "title": "Mineiro queima o mercúrio em uma mina de ouro",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Mineiro queima o mercúrio em uma mina de ouro.`}</em></p>
    <p>{`Archer se especializa en envenenamiento por mercurio; pasa una larga lista de sus efectos potenciales sobre la salud: enfermedades respiratorias, problemas neurológicos, daños al hígado, riñones y sistema inmunológico, por nombrar algunos; pero no pudo hacer mucho por sus pacientes en Guyana hasta que el tratamiento estuvo disponible en el 2018. Ese año, cuatro empleados de la Comisión de Geología y Minas de Guyana (GGMC) murieron durante un período de seis meses en un laboratorio dirigido por la Junta de Oro de Guyana (GBB), donde se quemaba el mercurio del oro en bruto. Funcionarios culparon a los vapores de mercurio. El laboratorio fue cerrado posteriormente y, desde entonces, se dispone de tratamiento de mercurio, principalmente importado de los EE.UU.`}</p>
    <p>{`Antes de eso, solo unas 5 a 10 personas al año venían a ver a Archer en Georgetown para hacerse una prueba de mercurio; la mayoría de las personas ni se tomaban la molestia, porque sabían que no había ayuda médica disponible.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Desde el 2018, la cantidad de mineros que buscan su atención médica se ha duplicado, pero, aun así, son solo de 10 a 20 personas al año.`}</p>
    </blockquote>
    <p>{`Desde el 2018, la cantidad de mineros que buscan su atención médica se ha duplicado, pero, aun así, son solo de 10 a 20 personas al año. Mientras, hay hasta 20.000 mineros artesanales en Guyana, y la gran mayoría trabaja con mercurio a diario. A Archer le preocupa que sean muy pocos los mineros que buscan ayuda en la capital por envenenamiento con mercurio, mismo ahora con el tratamiento disponible.`}</p>
    <p>{`“La prueba de mercurio es algo costosa en comparación con el salario de una persona en este país”, dice Archer. “Eso parece ser un elemento disuasivo”. Los análisis de sangre para detectar la intoxicación por mercurio y la batería de otras pruebas necesarias para determinar cuánto daño ha causado en todo el cuerpo pueden costar más de 40.000 GYD (lo que equivale a casi 200 dólares estadounidenses) o más de la mitad del salario mensual de muchos mineros a pequeña escala. El tratamiento posterior cuesta un promedio de 25.000 GYD adicionales.`}</p>
    <hr></hr>
    <p>{`La oración matutina bulle por los altavoces de la mezquita local de Corriverton a las 5 de la mañana. Todavía está oscuro, excepto por algunos faroles parpadeantes. De repente, el sonido de la mezquita se detiene, mientras un apagón envuelve el pueblo. Con la primera luz del sol sobre el río Corentin, un contrabandista aparece frente a una casa y camina por la entrada a la parte trasera de un patio para abrir las puertas metálicas que conducen al río. Es hora de irse.`}</p>
    <Video url="https://youtube.com/embed/eOLHxmi8WV4" mdxType="Video" />
    <p>{`El motor de una lancha rápida hace un estruendo junto al muelle cercano. A corta distancia, un indiano camina con el agua hasta la cintura y se lava para su oración matutina; no presta atención a lo que sucede a su lado. El contrabando se suele mover por la noche y se transporta a través del río hacia Surinam, en cruces informales conocidos como “backtrack” `}{`[rutas alternas]`}{`, siempre que el cambio de mareas del mar cercano permita un paso seguro por el río Corentin. Anoche la marea estaba demasiado baja, pero el capitán no está preocupado. “`}{`[El contrabando]`}{` nunca es incautado en el barco en agua”, dice, alejándose de la orilla del río hacia Surinam.`}</p>
    <p>{`“Mucha gente hace dinero en las backtrack”, añade. El mercurio, que se almacena en frascos de metal pesado, cada uno con un valor de hasta 8.000 dólares, se lleva al otro lado del río y a Surinam por varios puntos de la costa. Los contrabandistas a veces empacan hasta seis o siete frascos en cada barco.`}</p>
    <p>{`El tráfico de mercurio es un negocio estable, si no lucrativo para los capitanes, que cobran decenas de miles de dólares guyaneses por cada viaje a Surinam, según indica Showtime. “Es ilegal allá, pero es legal aquí, así que verás que la demanda es muy alta”, dice Showtime, mientras bebe cerveza y ron la noche anterior en Corriverton. Se mueven pequeñas cantidades por vez, y comparado con el traslado de drogas, como la cocaína, por la misma ruta, las consecuencias legales son mucho menores; pero sigue siendo un riesgo.`}</p>
    <p>{`“Si te atrapa la policía de Surinam, vas a la cárcel”, dice el capitán, después de apagar el ruidoso motor en medio del río Corentin; pero si todo sale bien, añade, “el tipo que lo compró estará en el otro lado esperando, con un taxi o su coche o lo que sea. Cuando lo dejamos, lo recoge”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Las rutas de tráfico son tan fluidas como el propio mercurio. Cambian rápidamente dependiendo de dónde centran su atención los agentes de orden público y de la dinámica de los mecanismos de oferta y demanda`}</p>
    </blockquote>
    <p>{`Las rutas de tráfico son tan fluidas como el propio mercurio. Cambian rápidamente dependiendo de dónde centran su atención los agentes de orden público y de la dinámica de los mecanismos de oferta y demanda que dictan el mercado negro de minerales. Hasta hace poco, este punto exacto era una ruta alterna activa para el tráfico de mercurio. Un nuevo puesto de control en el lado surinamés del río ha desviado el flujo de mercurio. Ahora los frascos se llevan río arriba, donde las orillas del río están cubiertas por una densa selva.`}</p>
    <p>{`“Sigue yendo a Surinam”, dice, pero ahora se lleva por “donde no hay ojos en la costa”.`}</p>
    <p><em parentName="p">{`*`}{`Algunos nombres han sido cambiados para proteger las identidades de los entrevistados.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      